import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Point } from "@turf/helpers";
import { Subject } from "rxjs";

import { ICategoryFieldModel } from "models";
import { ArpSegment, ForecastConstant } from "models/UserArpsModel";
import { PdenDataSourceSetting } from "models/pdenDataSourceSetting";

import { OnTime } from "components/forecasting/models/forecastWell";

import { Stick } from "../../../components/pad-generator/models/scenario";
import { EntityKind } from "../../../models/entityKind";

export interface ICheckedForecast {
  folderId: string;
  isFolder: boolean;
  id: string;
  type: "folder" | "forecast";
  title: string;
  reserveCategory?: string;
  children?: ICheckedForecast[];
  color?: string;
  thickness?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  wellData?: any;
  uniqueID: string;
  folderName: string;
  arps: ArpSegment[];
  constants: ForecastConstant[];
  source?: string;
  onTimeArray?: OnTime[];
}

export interface IUserForecastSettings {
  forecastSourceFolder: string;
  excludeMcDanielForecasts: boolean;
  excludeNoviForecasts: boolean;
  specialForecastFolders: string[];
  reserveCategory: string;
}

export interface SyncWells {
  activeWells: string[];
  centroid: Point;
  showWellsWithin: boolean;
  xMiles: number;
  /**
   * The sync is on/off
   */
  isSync: boolean;
  /**
   * Where the sync request came from
   */
  syncRequestOrigin: "Focus" | "Well Hierarchy";
  usePdenFromClient: boolean;
  useFcstFromClient: boolean;
  forecastRescat: string;
  schema: string;
}

export interface VisSettings {
  upHeight: number;
  downHeight: number;
  leftWidth: number;
  rightWidth: number;
}

export interface ColorLockedLegendItem {
  value: string;
  color: string;
  property: string;
  isPreferred?: boolean;
}

type ShowGeoMapLayer = {
  show: boolean;
  layerId: number;
  mapKind: number;
  field: string;
  min: number;
  max: number;
  unit: string;
};

export type ForecastModeT = "forecastTrump" | "forecastCompare";

export interface AppState {
  userForecastSettings: IUserForecastSettings;
  syncWells?: SyncWells;
  newFieldRequestObserver: Subject<CustomFieldPayload>;
  visSettings: VisSettings;
  normalizeTypeWell: boolean;
  pdenDataSourceSetting: PdenDataSourceSetting;
  lockedColors: { [propertyValueKey: string]: ColorLockedLegendItem };
  showGeoMapLayer?: ShowGeoMapLayer;
  activeEntityKinds: EntityKind[];
  forecastMode: ForecastModeT;
  useForecastStartDate: boolean;

  //the pad scenario is used to show the sticks on the map
  padScenario?: {
    scenarioId: string;
    showSticksOnMap: boolean;
    play: string;
    sticks?: Stick[];
  };
}

const LOCKED_COLOR_KEY = "locked-colors";

function loadLockedColorsFromSession(): {
  [propertyValueKey: string]: ColorLockedLegendItem;
} {
  const lockedJson = sessionStorage.getItem(LOCKED_COLOR_KEY);
  if (!lockedJson) {
    return {};
  }
  return JSON.parse(lockedJson);
}

const getDefaultActiveEntityKinds = () => {
  const storedEntityKinds: EntityKind[] = JSON.parse(
    sessionStorage.getItem("activeEntityKinds")
  );
  return storedEntityKinds ? storedEntityKinds : [EntityKind.Well];
};
const initialState: AppState = {
  lockedColors: loadLockedColorsFromSession(),

  visSettings: { leftWidth: 400, rightWidth: 400, upHeight: 50, downHeight: 50 },
  userForecastSettings: {
    forecastSourceFolder: null,
    excludeMcDanielForecasts: false,
    excludeNoviForecasts: true,
    reserveCategory: null,
    specialForecastFolders: []
  },
  newFieldRequestObserver: new Subject(),
  syncWells: null,
  normalizeTypeWell: true,
  pdenDataSourceSetting: {
    source: "Public",
    timeStep: "month"
  },
  activeEntityKinds: getDefaultActiveEntityKinds(),
  forecastMode: "forecastTrump",
  useForecastStartDate: false
};

export type CustomFieldType = "Calculated" | "Excel";

export interface CustomFieldPayload {
  type: CustomFieldType;
  isOrg: boolean;
  field?: ICategoryFieldModel;
  isEdit?: boolean;
  entityKind?: EntityKind;
}

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setDataSourceSetting(state, action: PayloadAction<PdenDataSourceSetting>) {
      state.pdenDataSourceSetting = action.payload;
    },
    setSyncWells(state, action: PayloadAction<SyncWells>) {
      state.syncWells = action.payload;
    },
    setNormalizeTypeWell(state, action: PayloadAction<boolean>) {
      state.normalizeTypeWell = action.payload;
    },
    setUserForecastSettings(state, action: PayloadAction<IUserForecastSettings>) {
      state.userForecastSettings = action.payload;
    },
    ///requests to add a new field, true is org fields, false is user fields
    requestAddNewField(state, action: PayloadAction<CustomFieldPayload>) {
      state.newFieldRequestObserver.next(action.payload);
    },
    setVisSettings(state, action: PayloadAction<VisSettings>) {
      state.visSettings = action.payload;
    },
    addToLockedColor(state, action: PayloadAction<ColorLockedLegendItem>) {
      const key = `${action.payload.property}:${action.payload.value}`;
      const clone = { ...state.lockedColors };
      clone[key] = action.payload;
      state.lockedColors = clone;
      sessionStorage.setItem(LOCKED_COLOR_KEY, JSON.stringify(state.lockedColors));
    },
    removeLockedColor(state, action: PayloadAction<ColorLockedLegendItem>) {
      const key = `${action.payload.property}:${action.payload.value}`;
      if (state.lockedColors[key]) {
        delete state.lockedColors[key];
      }
      sessionStorage.setItem(LOCKED_COLOR_KEY, JSON.stringify(state.lockedColors));
    },
    setShowGeoMapLayer(state, action: PayloadAction<ShowGeoMapLayer>) {
      state.showGeoMapLayer = action.payload;
    },
    setActiveEntities(state, action: PayloadAction<EntityKind[]>) {
      state.activeEntityKinds = action.payload;
      sessionStorage.setItem("activeEntityKinds", JSON.stringify(action.payload));
    },
    setForecastMode(state, action: PayloadAction<ForecastModeT>) {
      state.forecastMode = action.payload;
    },
    setPadScenario(
      state,
      action: PayloadAction<{
        scenarioId: string;
        showSticksOnMap: boolean;
        play: string;
        sticks?: Stick[];
      }>
    ) {
      state.padScenario = action.payload;
    },
    setUseForecastStartDate(state, action: PayloadAction<boolean>) {
      state.useForecastStartDate = action.payload;
    }
  }
});

export default appSlice.reducer;
export const {
  setUserForecastSettings,
  setSyncWells,
  requestAddNewField,
  setVisSettings,
  setNormalizeTypeWell,
  setDataSourceSetting,
  addToLockedColor,
  removeLockedColor,
  setPadScenario,
  setActiveEntities,
  setForecastMode,
  setUseForecastStartDate
} = appSlice.actions;
